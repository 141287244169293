<template>
  <div class="detail">
    <s-header :hasBack="true" :title="'错题详情'"></s-header>

    <div class="info" v-if="detailInfoObj">
      <div class="bookname" v-if="detailInfoObj.bookName">
        {{ detailInfoObj.bookName }}
      </div>
      <div style="margin-top: 10px">学案名称：{{ detailInfoObj.title }}</div>
      <div>目录：{{ detailInfoObj.tree_fullname }}</div>
      <div>发布老师：{{ detailInfoObj.teacherName }}老师</div>
      <div>完成用时：{{ secondsToIS(detailInfoObj.takeTime).chinese }}</div>
      <div>
        是否理解：
        <span v-if="detailInfoObj.comprehension" style="color: green"
          >理解</span
        >
        <span v-if="!detailInfoObj.comprehension" style="color: red"
          >不理解</span
        >
      </div>
    </div>

    <div class="wrongCase" v-if="detailInfoObj">
      <div class="question">
        <!-- 文字 -->
        <div class="text" v-if="detailInfoObj.way == 1 ? true : false">
          <div v-html="detailInfoObj.question"></div>
          <!-- {{ detailInfoObj.question }} -->
        </div>
        <!-- 图片 -->
        <div class="pic" v-if="detailInfoObj.way == 2 ? true : false">
          <van-image
            width="100%"
            height="100%"
            :src="prefix(detailInfoObj.question)"
            @click="showPreFn(prefix(detailInfoObj.question))"
          />
        </div>
        <div class="file" v-if="detailInfoObj.way == 3 ? true : false">
          <iframe
            :src="
              detailInfoObj.file_ext == 'pdf' || detailInfoObj.file_ext == 'txt'
                ? prefix(detailInfoObj.question)
                : prefixFile(prefix(detailInfoObj.question))
            "
            width="100%"
            height="350px"
          >
          </iframe>
        </div>
      </div>

      <div
        class="options"
        v-for="item in detailInfoObj.question_option"
        :key="item"
      >
        {{ item.text }}
      </div>

      <div class="myAnswer">
        <div v-if="detailInfoObj.type == 1 || detailInfoObj.type == 2">
          我的答案：{{ detailInfoObj.student_answer }}
        </div>
        <div v-if="detailInfoObj.type == 3 ? true : false">
          <h3>我的答案：</h3>
          <div>
            <van-image
              width="100%"
              height="100%"
              :src="prefix(detailInfoObj.student_answer)"
              @click="showPreFn(prefix(detailInfoObj.student_answer))"
            />
          </div>
        </div>
      </div>
      <div class="myAnswer2" v-if="detailInfoObj.student_answer2">
        <div v-if="detailInfoObj.type == 1 || detailInfoObj.type == 2">
          我的答案：{{ detailInfoObj.student_answer2 }}
        </div>
        <div v-if="detailInfoObj.type == 3 ? true : false">
          <h3>修正答案：</h3>
          <div>
            <!-- {{ prefix(detailInfoObj.student_answer2) }} -->
            <van-image
              width="100%"
              height="100%"
              :src="prefix(detailInfoObj.student_answer2)"
              @click="showPreFn(prefix(detailInfoObj.student_answer2))"
            />
          </div>
        </div>
      </div>
    </div>

    <van-image-preview
      v-model:show="showPre"
      :images="previewImgs"
      :loop="false"
      :maxZoom="2"
    >
      <template v-slot:index>{{ previewTitle }}</template>
    </van-image-preview>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import { apiGetWrongInfo } from "@/service/student.js";
import { secondsToIS, prefix, prefixFile } from "@/common/js/utils";

// 路由对象
const route = useRoute();

// 错题详情细信息
let detailInfoObj = ref(null);
// 是否显示预览图片
let showPre = ref(false);
let previewImgs = ref([]);
let previewTitle = ref("");

// 获取错题详情信息
const getDetailInfo = async () => {
  let res = await apiGetWrongInfo({ id: route.query.id });
  detailInfoObj.value = res.data;
};
getDetailInfo();

// 点击图片预览图片
const showPreFn = (url) => {
  showPre.value = true;
  previewImgs.value = [url];
};
</script>

<style lang="less" scoped>
.bookname {
  display: inline;
  padding: 5px;
  background-color: orange;
  border-radius: 5px;
}

@media screen and (orientation: landscape) {
  .detail {
    padding-top: 40px;
    display: flex;
    .info {
      width: 150px;
      padding: 10px;
      background-color: #fff;
    }

    .wrongCase {
      flex: 1;
      margin-left: 10px;
      overflow: scroll;
      padding-right: 10px;
    }
  }
}

@media screen and (orientation: portrait) {
  .info {
    padding: 10px;
  }

  .wrongCase {
    padding: 10px;
  }

  .info {
    background-color: #fff;
    margin-top: 10px;
  }
}
</style>
